<template>
  <div>
    <page-header title="Dashboard"></page-header>
    <div class="container mx-auto mt-5">
      <tournament-list-card></tournament-list-card>
      <tournament-invitations-list-card class="mt-3"></tournament-invitations-list-card>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
</style>