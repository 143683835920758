<template>
  <div>
    <span style="display: none;">{{ handicap }}</span>
    <div
      v-if="tournament.holes"
      id="holes"
      :class="{ 'hidden': selected_hole != null }"
      class="pb-5 ml-3 mr-3 lg:mr-0 md:ml-0"
    >
      <div class="grid grid-cols-1 pt-3 mb-3 lg:grid-cols-2">
        <div>
          <SubHeader
            title="Noch zu spielende Löcher"
            icon="fas fa-golf-ball"
            subtitle="Klicken Sie auf das zu spielende Loch um Ihren Score einzutragen."
          ></SubHeader>
        </div>
        <div
          class="flex justify-end p-3 mt-3 text-gray-800 rounded dark:text-gray-300"
        >
        </div>
      </div>

      <div
        class="grid grid-cols-1 gap-2 cursor-pointer lg:grid-cols-4 md:grid-cols-3"
      >
        <div
          v-for="(hole, index) in unplayed_holes"
          :key="hole.id"
          :class="{ 'bg-gray-200': selected_hole == hole, 'dark:bg-gray-600': selected_hole == hole }"
          class="grid grid-cols-1 p-3 bg-white border-b-2 rounded shadow md:grid-cols-2 dark:bg-gray-700 dark:text-gray-100"
          @click="selectHole(hole)"
        >
          <div class="text-lg font-bold uppercase">
            {{ hole.name }}
            <div
              v-if="index==0"
              class="text-sm font-semibold tracking-tight text-gray-700 dark:text-gray-300"
            >
              <span v-if="hole.start_date">Kanonenstart: {{ hole.start_date | moment('DD.MM - hh:mm') }}</span>
            </div>
          </div>
          <div class="text-sm text-right text-gray-600 dark:text-gray-200">
            Par: {{ hole.par }}<br>
            <span v-if="tournament.play_type === 'STABLEFORD' || tournament.play_type === 'BESTBALL_STABLEFORD'">Vorgabenschläge: {{ hole.extra_strokes }} <br></span>
            Index: {{ hole.handicap_index }}
          </div>
          <div
            v-if="hole.plays.length > 0 && hole.plays[0].score != 0"
            class="col-span-2 pt-2 mt-2 text-sm text-gray-800 border-t-2 text-bold"
          >
            <div class="grid grid-cols-2">
              <div class="text-base font-semibold text-gray-700 dark:text-gray-300">
                Strokes: {{ hole.plays[0].score }}
              </div>
              <div class="font-bold text-right">
                <div :class="classForScore(hole, hole.plays[0].score)">
                  {{ nameForScore(hole, hole.plays[0].score) }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="col-span-2 pt-2 mt-2 text-sm border-t-2 text-bold"
          >
            <div class="text-center text-gray-500">
              Noch nicht gespielt.
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 pt-3 mb-3 lg:grid-cols-2">
        <div class="mt-3 mb-3">
          <SubHeader
            title="Bereits gespielte Löcher"
            icon="fas fa-golf-ball"
            subtitle="Klicken Sie auf ein Loch, um Ihren Score zu bearbeiten."
          ></SubHeader>
        </div>
        <div
          class="flex justify-end p-3 mt-3 text-gray-800 rounded dark:text-gray-300"
        >
        </div>
      </div>

      <div
        class="grid grid-cols-1 gap-2 cursor-pointer lg:grid-cols-4 md:grid-cols-3"
      >
        <div
          v-for="(hole, index) in played_holes"
          :key="hole.id"
          :class="{ 'bg-gray-200': selected_hole == hole, 'dark:bg-gray-600': selected_hole == hole }"
          class="grid grid-cols-1 p-3 bg-white border-b-2 rounded shadow md:grid-cols-2 dark:bg-gray-700 dark:text-gray-100"
          @click="selectHole(hole)"
        >
          <div class="text-lg font-bold uppercase">
            {{ hole.name }}
            <div
              v-if="index==0"
              class="text-sm font-semibold tracking-tight text-gray-700 dark:text-gray-300"
            >
              <span v-if="hole.start_date">Kanonenstart: {{ hole.start_date | moment('DD.MM - hh:mm') }}</span>
            </div>
          </div>
          <div class="text-sm text-right text-gray-600 dark:text-gray-200">
            Par: {{ hole.par }}<br>
            <span v-if="tournament.play_type === 'STABLEFORD' || tournament.play_type === 'BESTBALL_STABLEFORD'">Vorgabenschläge: {{ hole.extra_strokes }} <br></span>
            Index: {{ hole.handicap_index }}
          </div>
          <div
            v-if="hole.plays.length > 0 && hole.plays[0].score != 0"
            class="col-span-2 pt-2 mt-2 text-sm text-gray-800 border-t-2 text-bold"
          >
            <div class="grid grid-cols-2">
              <div class="text-base font-semibold text-gray-700 dark:text-gray-300">
                Strokes: {{ hole.plays[0].score }}
              </div>
              <div class="font-bold text-right">
                <div :class="classForScore(hole, hole.plays[0].score)">
                  {{ nameForScore(hole, hole.plays[0].score) }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="col-span-2 pt-2 mt-2 text-sm border-t-2 text-bold"
          >
            <div class="text-center text-gray-500">
              Noch nicht gespielt.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="selected_hole"
      class="px-10 py-3 pb-12 mt-3 bg-white rounded shadow lg:px-12 dark:bg-gray-700 dark:text-gray-100"
    >
      <div class="grid grid-cols-1 pb-3 mb-5 border-b-2 lg:grid-cols-3">
        <div>
          <h4
            class="w-full mt-5 mb-4 text-xl font-bold text-teal-600 uppercase dark:text-teal-400 "
          >
            {{ selected_hole.name }}
          </h4>
        </div>
        <div class="col-span-2">
          <button
            class="w-full p-2 mb-1 text-gray-800 border border-gray-500 rounded md:w-auto dark:text-white lg:float-right lg:mt-3 md:mb-4 focus:outline-none focus:shadow-solid"
            @click="cancelHole(selected_hole)"
          >
            Abbrechen
          </button>
          <button
            class="w-full p-2 mt-1 mb-4 mr-3 font-bold text-white bg-teal-700 border border-teal-700 rounded md:w-auto lg:float-right lg:mt-3 focus:outline-none focus:shadow-solid"
            @click="saveHole()"
          >
            Speichern
          </button>
        </div>
      </div>

      <h4
        v-if="edit_score <= selected_hole.handicap_par + 3"
        class="w-full mb-4 text-xl font-bold text-teal-600 uppercase dark:text-teal-400 lg:mb-8"
      >
        Schlaganzahl eingeben (Schnelleingabe)
      </h4>
      <div
        v-if="edit_score <= selected_hole.handicap_par + 3"
        class="hidden pr-8 lg:block"
      >
        <vue-slider
          v-model="edit_score"
          :marks="marksForSelectedHole()"
          :min="1"
          tooltip="always"
          :max="selected_hole.handicap_par + 3"
          :interval="1"
        ></vue-slider>
      </div>
      <div
        class="block py-2 mt-4 mb-4 mb-8 lg:hidden lg:mb-0"
        :class="classForScore(selected_hole, selected_hole.plays[0].score)"
      >
        {{ nameForScore(selected_hole, selected_hole.plays[0].score) }}
      </div>
      <div
        v-if="edit_score <= selected_hole.handicap_par + 3"
        class="block pl-8 lg:hidden"
      >
        <vue-slider
          v-model="edit_score"
          direction="ttb"
          :height="350"
          :marks="marksForSelectedHoleMobile()"
          :min="1"
          tooltip="always"
          :max="selected_hole.handicap_par + 3"
          :interval="1"
        ></vue-slider>
      </div>
      <h4 class="w-full mt-10 mb-2 text-xl font-bold text-teal-600 uppercase dark:text-teal-400 lg:mb-2">
        Manuelle Eingabe
      </h4>
      <p class="block mb-2 text-sm font-semibold leading-tight tracking-wide text-gray-500 uppercase">
        Bitte geben Sie hier Ihre benötigten Schläge an
      </p>
      <number-input
        v-model="edit_score"
        :errors="null"
        name="edit_score"
        step="1"
        min="1"
        max="30"
        label="Ihr Schläge"
      ></number-input>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Swal from 'sweetalert2'
import gql from 'graphql-tag'
import SubHeader from '../ui/SubHeader'

export default {
  components: {
    VueSlider,
    SubHeader
  },
  props: {
    tournament: {
      type: Object,
      default: null,
    },
    handicap: {
      type: Number,
      default: 0,
    },
    courseHandicap: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected_hole: null,
      edit_score: 0,
      logged_user: window.logged_user
    }
  },
  computed: {
    unplayed_holes() {
      return this.tournament.holes.filter(function (hole) {
        return hole.plays.length == 0
      })
    },
    played_holes() {
      return this.tournament.holes.filter(function (hole) {
        return hole.plays.length > 0
      })
    }
  },
  methods: {
    nameForScore(hole, score) {
      if (score == 1) {
        return 'Ace!'
      } else if (score == hole.handicap_par - 3) {
        return 'Albatross'
      } else if (score == hole.handicap_par - 2) {
        return 'Eagle'
      } else if (score == hole.handicap_par - 1) {
        return 'Birdie'
      } else if (score == hole.handicap_par) {
        return 'Par'
      } else if (score == hole.handicap_par + 1) {
        return 'Bogey'
      } else if (score == hole.handicap_par + 2) {
        return 'Double Bogey'
      } else if (score == hole.handicap_par + 3) {
        return 'Triple Bogey'
      } else if (score == hole.handicap_par + 4) {
        return 'Quadrouple Bogey'
      } else {
        return '-'
      }
    },
    classForScore(hole, score) {
      if (score == 1) {
        return 'bg-pink-600 p-1 text-white rounded text-center'
      } else if (score < hole.handicap_par) {
        return 'bg-green-500 p-1 text-white rounded text-center'
      } else if (score == hole.handicap_par) {
        return 'bg-gray-500 p-1 text-white rounded text-center'
      } else {
        return 'bg-red-500 p-1 text-white rounded text-center'
      }
    },
    cancelHole(hole) {
      hole.plays = []
      this.selected_hole = null
    },
    selectHole(hole) {
      if (hole.plays.length == 0) {
        hole.plays = [
          {
            score: 1,
          },
        ]
      }

      this.selected_hole = hole
      this.edit_score = hole.plays[0].score
    },
    saveHole() {
      this.selected_hole.plays[0].score = this.edit_score

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($tournament_id: ID!, $user_id: ID!, $hole_id: ID!, $score: Int!, $handicap: Float) {
              createOrUpdatePlay(
                tournament_id: $tournament_id,
                user_id: $user_id,
                hole_id: $hole_id,
                score: $score,
                handicap: $handicap
              ) {
                id
              }
            }
          `,
          // Parameters
          variables: {
            tournament_id: this.tournament.id,
            hole_id: this.selected_hole.id,
            user_id: window.logged_user.id,
            score: this.edit_score,
            handicap: this.handicap
          },
        })
        .then(data => {
          Swal.fire({
            title: 'Score gespeichert',
            text: 'Der eingegebene Score wurde erfolgreich gespeichert.',
            timerProgressBar: true,
            icon: 'success',
            position: 'center',
          })

          this.selected_hole = null
        })
        .catch(error => {
          Swal.fire({
            title: 'Fehler',
            text: 'Beim Speichern des Scores ist ein Fehler aufgetreten.',
            timerProgressBar: true,
            icon: 'error',
            position: 'center',
          })
        })


    },
    marksForSelectedHole() {
      var marks = {}

      marks[this.selected_hole.handicap_par - 2] = {
        label: 'Eagle (' + (this.selected_hole.handicap_par - 2) + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: '#48bb78',
          transform: 'translate(-4px, -4px)',
        },
        labelStyle: {
          color: '#48bb78',
          fontWeight: 'bold',
        },
      }

      marks[this.selected_hole.handicap_par - 1] = {
        label: 'Birdie (' + (this.selected_hole.handicap_par - 1) + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: '#48bb78',
          transform: 'translate(-4px, -4px)',
        },
        labelStyle: {
          color: '#48bb78',
          fontWeight: 'bold',
        },
      }

      marks[this.selected_hole.handicap_par] = {
        label: 'Par (' + this.selected_hole.handicap_par + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: '#333',
          transform: 'translate(-4px, -4px)',
        },
        labelStyle: {
          color: '#333',
          fontWeight: 'bold',
        },
      }

      marks[this.selected_hole.handicap_par + 1] = {
        label: 'Bogey (' + (this.selected_hole.handicap_par + 1) + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: 'red',
          transform: 'translate(-4px, -4px)',
        },
        labelStyle: {
          color: '#f56565',
          fontWeight: 'bold',
        },
      }

      marks[this.selected_hole.handicap_par + 2] = {
        label: 'Do. Bogey (' + (this.selected_hole.handicap_par + 2) + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: '#f56565',
          transform: 'translate(-4px, -4px)',
        },
        labelStyle: {
          color: '#f56565',
          fontWeight: 'bold',
        },
      }

      marks[this.selected_hole.handicap_par + 3] = {
        label: 'Tr. Bogey (' + (this.selected_hole.handicap_par + 3) + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: '#f56565',
          transform: 'translate(-4px, -4px)',
        },
        labelStyle: {
          color: '#f56565',
          fontWeight: 'bold',
        },
      }

      marks[1] = {
        label: 'Ace! (1)',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: 'd53f8c',
          transform: 'translate(-4px, -4px)',
        },
        labelStyle: {
          color: '#d53f8c',
          fontWeight: 'bold',
        },
      }

      return marks
    },
    marksForSelectedHoleMobile() {
      var marks = {}

      marks[this.selected_hole.par - 2] = {
        label: 'Eagle (' + (this.selected_hole.par - 2) + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: '#48bb78',
          transform: 'translate(-4px, 4px)',
        },
        labelStyle: {
          color: '#48bb78',
          fontWeight: 'bold',
        },
      }

      marks[this.selected_hole.par - 1] = {
        label: 'Birdie (' + (this.selected_hole.par - 1) + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: '#48bb78',
          transform: 'translate(-4px, 4px)',
        },
        labelStyle: {
          color: '#48bb78',
          fontWeight: 'bold',
        },
      }

      marks[this.selected_hole.par] = {
        label: 'Par (' + this.selected_hole.par + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: '#333',
          transform: 'translate(-4px, 4px)',
        },
        labelStyle: {
          color: '#333',
          fontWeight: 'bold',
        },
      }

      marks[this.selected_hole.par + 1] = {
        label: 'Bogey (' + (this.selected_hole.par + 1) + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: 'red',
          transform: 'translate(-4px, 4px)',
        },
        labelStyle: {
          color: '#f56565',
          fontWeight: 'bold',
        },
      }

      marks[this.selected_hole.par + 2] = {
        label: 'Double Bogey (' + (this.selected_hole.par + 2) + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: '#f56565',
          transform: 'translate(-4px, 4px)',
        },
        labelStyle: {
          color: '#f56565',
          fontWeight: 'bold',
        },
      }

      marks[this.selected_hole.par + 3] = {
        label: 'Triple Bogey (' + (this.selected_hole.par + 3) + ')',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: '#f56565',
          transform: 'translate(-4px, 4px)',
        },
        labelStyle: {
          color: '#f56565',
          fontWeight: 'bold',
        },
      }

      marks[1] = {
        label: 'Ace! (1)',
        style: {
          width: '12px',
          height: '12px',
          display: 'block',
          backgroundColor: 'd53f8c',
          transform: 'translate(-4px, 4px)',
        },
        labelStyle: {
          color: '#d53f8c',
          fontWeight: 'bold',
        },
      }

      return marks
    },
  },
}
</script>

<style lang="scss" scoped>
</style>