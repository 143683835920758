<template>
  <div>
    <page-header title="Profil"></page-header>
    <div class="container pt-5 pb-10 mx-auto">
      <div class="flex flex-wrap justify-center">
        <div class="w-full max-w-lg">
          <div class="flex flex-col text-gray-100 break-words bg-white border border-2 rounded shadow-md dark:bg-gray-900">
            <spinner v-if="this.$apollo.queries.me.loading"></spinner>

            <form
              v-if="!this.$apollo.queries.me.loading"
              class="w-full p-6"
              @submit.prevent
            >
              <div class="flex flex-wrap mb-6">
                <label
                  for="salutation"
                  class="block mb-2 text-sm font-bold text-gray-700 dark:text-gray-100"
                >
                  Anrede:
                </label>
                <div class="relative inline-block w-full">
                  <select
                    v-model="me.salutation"
                    name="salutation"
                    class="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-400 rounded shadow appearance-none dark:bg-gray-800 dark:border-gray-600 hover:border-gray-500 focus:outline-none focus:shadow-outline"
                  >
                    <option value="1">
                      Herr
                    </option>
                    <option value="2">
                      Frau
                    </option>
                  </select>
                  <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg
                      class="w-4 h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    ><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                  </div>
                </div>
              </div>

              <text-input
                v-model="me.title_prefix"
                :errors="errors"
                name="title_prefix"
                label="Titel vorangestellt"
              ></text-input>
              <text-input
                v-model="me.first_name"
                :errors="errors"
                name="first_name"
                label="Vorname"
              ></text-input>
              <text-input
                v-model="me.last_name"
                :errors="errors"
                name="last_name"
                label="Nachname"
              ></text-input>
              <text-input
                v-model="me.title_suffix"
                :errors="errors"
                name="title_suffix"
                label="Titel nachgestellt"
              ></text-input>
              <number-input
                v-model="me.handicap"
                :errors="errors"
                name="handicap"
                step="0.1"
                min="-100"
                max="100"
                label="Ihr Handicap (typischerweise < 0)"
              ></number-input>
              <text-input
                v-model="me.email"
                :errors="errors"
                type="email"
                name="email"
                label="E-Mail"
              ></text-input>
              <text-input
                v-model="me.phone"
                :errors="errors"
                name="phone"
                label="Telefonnummer"
              ></text-input>

              <div class="flex flex-wrap items-center">
                <button
                  :disabled="saving"
                  class="w-full px-4 py-2 font-bold text-gray-100 transition duration-200 bg-blue-500 rounded disabled:opacity-75 display-block dark:bg-teal-900 dark:text-gray-100 dark-hover:bg-teal-800 disabled:cursor-not-allowed hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                  @click="save()"
                >
                  Profil speichern
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import Swal from 'sweetalert2'


export default {
  data() {
    return {
      me: {
        id: null,
        salutation: null,
        first_name: null,
        last_name: null,
        title_prefix: null,
        title_suffix: null,
        handicap: 0,
        phone: null,
        email: null
      },
      password: '',
      saving: false,
      errors: null
    }
  },
  apollo: {
    me:
                gql`query {
                    me {

                            id,
                            salutation,
                            first_name,
                            last_name,
                            title_prefix,
                            title_suffix,
                            handicap,
                            phone,
                            email

                    }
                }`
  },
  methods: {
    save() {
      const me = this.me
      const password = this.password

      if(this.saving) {
        return
      }

      this.saving = true

      this.$apollo.mutate({
        // Query
        mutation: gql`mutation ($id: ID!, $salutation: Int, $first_name: String, $last_name: String, $title_prefix: String, $title_suffix: String,  $handicap: Float, $phone: String, $email: String) {
                        updateUser(id: $id, salutation: $salutation, first_name: $first_name, last_name: $last_name, title_prefix: $title_prefix, title_suffix: $title_suffix, handicap: $handicap phone: $phone, email: $email) {
                            id,
                            salutation,
                            first_name,
                            last_name,
                            title_prefix,
                            title_suffix,
                            handicap,
                            phone,
                            email
                        }
                    }`,
        // Parameters
        variables: {
          id: me.id,
          salutation: me.salutation,
          first_name: me.first_name,
          last_name: me.last_name,
          title_prefix: me.title_prefix,
          title_suffix: me.title_suffix,
          handicap: me.handicap,
          phone: me.phone,
          email: me.email,
        },
      }).then((data) => {
        this.saving = false
        this.errors = null
        Swal.fire({
          title: 'Profil aktualisiert',
          text: 'Ihr Profi wurde erfolgreich aktualisiert',
          timerProgressBar: true,
          icon: 'success',
          position: 'center'
        })

        window.logged_user.first_name = data.data.updateUser.first_name
        window.logged_user.last_name = data.data.updateUser.last_name
        window.logged_user.email = data.data.updateUser.email
        window.logged_user.handicap = data.data.updateUser.handicap
        window.logged_user.salutation = data.data.updateUser.salutation

      }).catch((error) => {
        Swal.fire({
          title: 'Fehler',
          text: 'Beim Speichern Ihres Profils ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben',
          timerProgressBar: true,
          icon: 'error',
          position: 'center'
        })
        this.saving = false
        this.errors = error.graphQLErrors[0].extensions.validation

      })
    }
  },


}
</script>

<style lang="scss" scoped>

</style>