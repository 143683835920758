<template>
  <div class="flex flex-wrap mb-6">
    <label
      for="name"
      class="block dark:text-gray-100 text-gray-700 text-sm font-bold mb-2"
    >
      {{ label }}:
    </label>
    <input
      :id="name"
      :class="{ 'border-red-400': errors && errors[name] }"
      :name="name"
      :type="type"
      :value="value"
      :required="required"
      class="shadow appearance-none dark:bg-gray-800 dark:border-gray-600 dark:text-gray-200 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      @input="updateValue($event.target.value)"
    >
        
    <validation-error
      :errors="errors"
      :name="name"
    ></validation-error>
  </div> 
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      default: []
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>

