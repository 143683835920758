/* eslint-disable indent */
<template>
  <div>
    <div
      v-if="this.$apollo.queries.tournament.loading"
      class="container mx-auto"
    >
      <spinner></spinner>
    </div>

    <!-- <page-header
      v-if="
        tournament != null && !this.$apollo.queries.tournament.loading
      "
      :title="tournament.name"
    ></page-header> -->
    <page-header
      v-if="
        tournament == null && !this.$apollo.queries.tournament.loading
      "
      title="Turnier nicht gefunden"
      :full="true"
      subtitle="Kein Tunier mit dieser ID vorhanden."
    ></page-header>

    <page-header
      v-if="
        scores &&
          scores.length == 0 &&
          tournament != null &&
          !this.$apollo.queries.tournament.loading
      "
      :full="true"
      :title="tournament.name"
      subtitle="Turnier noch nicht begonnen"
    ></page-header>

    <page-header
      v-if="
        scores &&
          scores.length > 0 &&
          tournament != null &&
          !this.$apollo.queries.tournament.loading
      "
      :full="true"
      :title="tournament.name"
      subtitle="Turnier läuft"
    ></page-header>

    <div
      v-if="
        scores &&
          tournament != null &&
          !this.$apollo.queries.tournament.loading
      "
      class="p-3 px-10 mx-auto mt-3 bg-white border-b-2 rounded shadow dark:bg-gray-700 dark:text-gray-100"
    >
      <SubHeader
        title="Scoreboard"
        :subtitle="scoringDescription()"
        icon="fas fa-table"
      >
        <div class="relative inline-block w-full">
          <select
            v-model="selected_scoring"
            name="salutation"
            class="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-400 rounded shadow appearance-none dark:bg-gray-800 dark:border-gray-600 hover:border-gray-500 focus:outline-none focus:shadow-outline"
          >
            <option value="1">
              Stableford
            </option>
            <option value="2">
              Stroke Play
            </option>
            <option value="3">
              Best Ball - Stableford (Netto)
            </option>
            <option value="4">
              Best Ball - Stroke Play (Brutto)
            </option>
          </select>
          <div
            class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
          >
            <svg
              class="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </SubHeader>
      <div class="overflow-scroll">
        <table class="w-full mt-3 table-auto">
          <thead>
            <tr
              class="bg-gray-200 dark:bg-gray-400 dark:text-gray-700"
            >
              <th class="pt-3 pb-3 border">
                #
              </th>
              <th
                class="pt-3 pb-3 border"
                colspan="2"
              >
                Hole
              </th>
              <th
                v-for="(hole, index) in tournament.holes"
                :key="hole.id"
                class="w-8 pt-3 pb-3 border"
              >
                {{ index + 1 }}
              </th>
              <th class="pt-3 pb-3 border">
                Total
              </th>
              <th
                v-if="
                  selected_scoring == 2 ||
                    selected_scoring == 4
                "
                class="pt-3 pb-3 border"
              >
                +/-
              </th>
            </tr>
            <tr
              class="bg-gray-400 dark:bg-gray-500 dark:text-gray-700"
            >
              <th class="pt-3 pb-3 border"></th>
              <th
                class="pt-3 pb-3 border"
                colspan="2"
              >
                Par
              </th>
              <th
                v-for="hole in tournament.holes"
                :key="hole.id"
                class="w-8 pt-3 pb-3 border"
              >
                {{ hole.par }}
              </th>
              <th
                style="width: 60px" 
                class="pt-3 pb-3 border"
              >
                {{ hole_par_sum }}
              </th>
              <th
                v-if="
                  selected_scoring == 2 ||
                    selected_scoring == 4
                "
                style="width: 60px"
                class="pt-3 pb-3 border"
              ></th>
            </tr>
          </thead>
          <tbody v-if="selected_scoring == 4">
            <tr
              v-for="(score, index) in group_scores"
              :key="index"
              class="text-center dark:bg-gray-400 dark:text-gray-700"
            >
              <td
                class="pt-3 pb-3 border"
                style="width: 40px"
              >
                {{ index + 1 }}
              </td>
              <td
                class="px-2 pt-3 pb-3 border"
                style="width: 450px !important;"
              >
                {{ score.title }}
                <div
                  class="w-full mt-2 text-xs"
                >
                  <div>
                    {{ score.user_names }}
                  </div>
                </div>
              </td>
              <td
                class="pt-3 pb-3 border"
                style="width: 65px"
              >
                {{ score.handicap }}
              </td>
              <td
                v-for="(play_score, play_index) in score.scores"
                :key="play_index"
                :class="{
                  'bg-green-400 text-white ': play_score == 1,
                  'bg-green-600 text-green-200': play_score < tournament.holes[play_index].par && (play_score != 0 && play_score != 1 && play_score != -1),
                  'bg-red-600 text-red-200': (play_score > (tournament.holes[play_index].par + 2)) && (play_score != 0 && play_score != 1 && play_score != -1),
                  'bg-red-300 text-gray-800': (play_score > (tournament.holes[play_index].par + 1)) && (play_score != 0 && play_score != 1 && play_score != -1),
                }"
                class="w-8 pt-3 pb-3 border"
              >
                <span v-if="play_score != -1">
                  {{ play_score }}
                </span>
              </td>
              <td
                class="pt-3 pb-3 border"
                style="width: 60px"
              >
                {{ score.gross_score }}
              </td>
              <td
                class="pt-3 pb-3 border"
                style="width: 60px"
              >
                {{ score.difference }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="selected_scoring == 3">
            <tr
              v-for="(score, index) in group_scores"
              :key="index"
              class="text-center dark:bg-gray-400 dark:text-gray-700"
            >
              <td
                class="pt-3 pb-3 border"
                style="width: 40px"
              >
                {{ index + 1 }}
              </td>
              <td
                class="pt-3 pb-3 border"
                style="width: 450px;"
              >
                {{ score.title }}
                <div
                  class="w-full mt-2 text-xs"
                >
                  <div>
                    {{ score.user_names }}
                  </div>
                </div>
              </td>
              <td
                class="pt-3 pb-3 border"
                style="width: 65px"
              >
                {{ score.handicap }}
              </td>
              <td
                v-for="(play_score,
                        play_index) in score.stableford_scores"
                :key="play_index"
                :class="{
                  'bg-gray-200 text-gray-800 ': play_score == -1,
                  'bg-gray-200 text-gray-800 ': play_score == 2,
                  'bg-green-400 text-white ': play_score == 5,
                  'bg-green-600 text-green-200': play_score == 3 || play_score == 4,
                  'bg-red-600 text-red-200': play_score == 0,
                  'bg-red-300 text-gray-800': play_score == 1,
                }"
                class="w-8 pt-3 pb-3 border"
              >
                <span v-if="play_score != -1">
                  {{ play_score }}
                </span>
              </td>
              <td class="pt-3 pb-3 border">
                {{ score.stableford_total }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="selected_scoring == 2">
            <tr
              v-for="(score, index) in scores"
              :key="index"
              class="text-center dark:bg-gray-400 dark:text-gray-700"
            >
              <td
                class="pt-3 pb-3 border"
                style="width: 40px"
              >
                {{ index + 1 }}
              </td>
              <td
                class="pt-3 pb-3 border"
                style="width: 450px;"
              >
                {{ score.title }}
              </td>
              <td
                class="pt-3 pb-3 border"
                style="width: 65px"
              >
                {{ score.handicap }}
              </td>
              <td
                v-for="(play_score, play_index) in score.scores"
                :key="play_index"
                :class="{
                  'bg-gray-400 dark:bg-gray-200 text-gray-800 ':
                    play_score != 0 && play_score != -1,
                }"
                class="w-8 pt-3 pb-3 border"
              >
                <span v-if="play_score != -1">
                  {{ play_score }}
                </span>
              </td>
              <td class="pt-3 pb-3 border">
                {{ score.gross_score }}
              </td>
              <td class="pt-3 pb-3 border">
                {{ score.difference }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="selected_scoring == 1">
            <tr
              v-for="(score, index) in scores"
              :key="index"
              class="text-center dark:bg-gray-400 dark:text-gray-700"
            >
              <td
                class="pt-3 pb-3 border"
                style="width: 40px"
              >
                {{ index + 1 }}
              </td>
              <td
                class="pt-3 pb-3 border"
                style="width: 450px;"
              >
                {{ score.title }}
              </td>
              <td
                class="pt-3 pb-3 border"
                style="width: 65px"
              >
                {{ score.handicap }}
              </td>
              <td
                v-for="(play_score,
                        play_index) in score.stableford_scores"
                :key="play_index"
                :class="{
                  'bg-gray-200 text-gray-800 ': play_score == -1,
                  'bg-gray-200 text-gray-800 ': play_score == 2,
                  'bg-green-400 text-white ': play_score == 5,
                  'bg-green-600 text-green-200': play_score == 3 || play_score == 4,
                  'bg-red-600 text-red-200': play_score == 0,
                  'bg-red-300 text-gray-800': play_score == 1,
                }"
                class="w-8 pt-3 pb-3 border"
              >
                <span v-if="play_score != -1">
                  {{ play_score }}
                </span>
              </td>
              <td class="pt-3 pb-3 border">
                {{ score.stableford_total }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import _ from 'lodash'
import SubHeader from '../ui/SubHeader'
import MarqueeText from 'vue-marquee-text-component'

export default {
  components: {
    SubHeader,
    MarqueeText
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected_scoring: 3,
      hole_par_sum: 0,
      scores: [],
      group_scores: [],
      tournament: null,
      logged_user: window.logged_user,
    }
  },
  watch: {
    selected_scoring() {
      if (this.selected_scoring == 2) {
        this.scores.sort((a, b) =>
          a.gross_score > b.gross_score ? 1 : -1
        )
      } 
      else if (this.selected_scoring == 1) {
        this.scores.sort((a, b) =>
          a.stableford_total < b.stableford_total ? 1 : -1
        )
      }
      else if (this.selected_scoring == 3) {
        this.group_scores.sort((a, b) =>
          a.stableford_total < b.stableford_total ? 1 : -1
        )
      }
      else if (this.selected_scoring == 4) {
        this.group_scores.sort((a, b) =>
          a.gross_score > b.gross_score ? 1 : -1
        )
      }
    },
    tournament() {
      this.tournament.holes.forEach((hole) => {
        this.hole_par_sum += hole.par
      })

      var calc_score = {
        users: [],
      }

      var group_calc_score = {
        groups: [],
      }

      this.tournament.groups.forEach((group) => {
        var handicap = 0
        group_calc_score.groups[group.id] = group
        
        group.users.forEach((user) => {

          calc_score.users[user.id] = user

          if (user.pivot.handicap) {
            calc_score.users[user.id]['handicap'] = user.pivot.handicap
          } else {
            calc_score.users[user.id]['handicap'] = user.handicap
          }

          //Accumulate all handicaps for users in flight
          handicap += calc_score.users[user.id]['handicap']

          if (user.pivot.color) {
            calc_score.users[user.id]['course_rating'] = user.pivot.course_rating
            calc_score.users[user.id]['slope'] = user.pivot.slope
          } else {
            calc_score.users[user.id]['course_rating'] = this.tournament.course.course_rating
            calc_score.users[user.id]['slope'] = this.tournament.course.slope
          }
        })



        if(group.handicap) {
          group_calc_score.groups[group.id]['handicap'] = group.handicap
        }
        else {
          group_calc_score.groups[group.id]['handicap'] = handicap / group.users.length
        }

        group_calc_score.groups[group.id]['user_names'] = group.users.map(e => e.first_name + ' ' + e.last_name +   '(' + e.id + ')').join(', ')
      })


      if (this.tournament.play_type == 'STABLEFORD') {
        this.selected_scoring = 1
      } else if (this.tournament.play_type == 'STROKE_PLAY') {
        this.selected_scoring = 2
      } else if (this.tournament.play_type == 'BESTBALL_STABLEFORD') {
        this.selected_scoring = 3
      } else {
        this.selected_scoring = 4
      }

      //Calculate Extra Strokes per User
      calc_score.users.forEach((user) => {
        var extra_strokes = this.courseHandicapForUser(
          user,
          calc_score
        )

        this.tournament.holes.sort((a, b) =>
          a.handicap_index > b.handicap_index ? 1 : -1
        )

        //Reset Calculation
        this.tournament.holes.forEach((hole) => {
          hole['extra_strokes'] = 0
          hole['handicap_par'] = hole.par
        })

        do {
          this.tournament.holes.forEach((hole) => {
            if (extra_strokes > 0) {
              hole['extra_strokes']++
              hole['handicap_par']++
            }

            extra_strokes--
          })
        } while (extra_strokes > 0)

        this.tournament.holes.sort((a, b) =>
          a.sort_order > b.sort_order ? 1 : -1
        )

        calc_score.users[user.id]['scores'] = []
        calc_score.users[user.id]['stableford_scores'] = []
        calc_score.users[user.id]['gross_score'] = 0
        calc_score.users[user.id]['net_score'] = 0
        calc_score.users[user.id]['stableford_score'] = 0

        this.tournament.holes.forEach((hole) => {
          var score = -1

          var foundPlay = hole.plays.find(
            (play) => play.user_id == user.id
          )

          if (foundPlay) {
            score = foundPlay.score
          }

          var stableford_score = -1

          if (score != -1) {
            var difference = hole.handicap_par - score
            stableford_score = this.stablefordPointsForDifference(difference)
          }
          
          calc_score.users[user.id].scores.push(score)
          if(score != -1) {
            calc_score.users[user.id].gross_score += score
          } 

          calc_score.users[user.id].stableford_scores.push(stableford_score)
          if(stableford_score != -1) {
            calc_score.users[user.id].stableford_score += stableford_score
          }
        })

        calc_score.users[user.id].net_score = calc_score.users[user.id].gross_score - calc_score.users[user.id].handicap
      })

      calc_score.users.forEach((user) => {
        var checkForOne = function(arr){
          return (new Set(arr)).size !== 1
        }
        
        if(checkForOne(user.scores)) {
          this.scores.push({
            title: user.first_name + ' ' + user.last_name,
            handicap: user.handicap,
            scores: user.scores,
            stableford_scores: user.stableford_scores,
            stableford_total: user.stableford_score,
            net_score: user.net_score,
            gross_score: user.gross_score,
            difference: user.gross_score - this.hole_par_sum,
          })
        }

      
      })

      //Find the Best Scores per Group / per Hole
      this.tournament.groups.forEach((group) => {
        group_calc_score.groups[group.id]['scores'] = []

        group.users.forEach((user, user_index) => {
          this.tournament.holes.forEach((hole, index) => {
            if (user_index == 0) {
              group_calc_score.groups[group.id].scores[index] = 99
            }

            if (calc_score.users[user.id]['scores'][index] < group_calc_score.groups[group.id].scores[index] && calc_score.users[user.id]['scores'][index] != -1) {
              group_calc_score.groups[group.id].scores[index] = calc_score.users[user.id]['scores'][index]
            }
          })
        })

        //Reset the Scoring to 0 if it has not been set
        group_calc_score.groups[group.id].scores.forEach(
          (score, index) => {
            if (score == 99) {
              group_calc_score.groups[group.id]['scores'][index] = -1
            }
          }
        )

        //Calculate Scorings for Groups
        group_calc_score.groups.forEach((group) => {
          var extra_strokes = this.courseHandicapForGroup(group,group_calc_score)

          this.tournament.holes.sort((a, b) =>
            a.handicap_index > b.handicap_index ? 1 : -1
          )

          this.tournament.holes.forEach((hole) => {
            hole['extra_strokes'] = 0
            hole['handicap_par'] = hole.par
          })

          do {
            this.tournament.holes.forEach((hole) => {
              if (extra_strokes > 0) {
                hole['extra_strokes']++
                hole['handicap_par']++
              }

              extra_strokes--
            })
          } while (extra_strokes > 0)

          this.tournament.holes.sort((a, b) =>
            a.sort_order > b.sort_order ? 1 : -1
          )

          group_calc_score.groups[group.id]['stableford_scores'] = []
          group_calc_score.groups[group.id]['gross_score'] = 0
          group_calc_score.groups[group.id]['net_score'] = 0
          group_calc_score.groups[group.id]['stableford_score'] = 0

          this.tournament.holes.forEach((hole, index) => {
            var score = 0

            if (!('scores' in group_calc_score.groups[group.id])) {
              group_calc_score.groups[group.id].scores = []
            } 
            else {
              score = group_calc_score.groups[group.id].scores[index]
            }

            var stableford_score = -1
            
            if (score != 0 && score != -1) {

              var difference = hole.handicap_par - score
              stableford_score = this.stablefordPointsForDifference(difference)
            }

            if(score != -1) {
              group_calc_score.groups[group.id].gross_score += score
            }
            group_calc_score.groups[group.id].stableford_scores.push(stableford_score)
            if(stableford_score != -1) {
              group_calc_score.groups[group.id].stableford_score += stableford_score
            }
          })

          group_calc_score.groups[group.id].net_score = group_calc_score.groups[group.id].gross_score - group_calc_score.groups[group.id].handicap
        })
      })

      //Fill the Vue Scoring Array for Groups
      group_calc_score.groups.forEach((group) => {
      
        this.group_scores.push({
          title: group.name,
          user_names: group.user_names,
          handicap: group.handicap.toFixed(2),
          scores: group.scores,
          stableford_scores: group.stableford_scores,
          stableford_total: group.stableford_score,
          net_score: group.net_score,
          gross_score: group.gross_score,
          difference: group.gross_score - this.hole_par_sum,
        })
        

      
      })

      this.tournament.holes.sort((a, b) =>
        a.sort_order > b.sort_order ? 1 : -1
      )

      if (this.selected_scoring == 2) {
        this.scores.sort((a, b) =>
          a.gross_score > b.gross_score ? 1 : -1
        )
      } else if (this.selected_scoring == 1) {
        this.scores.sort((a, b) =>
          a.stableford_total < b.stableford_total ? 1 : -1
        )
      }
      else if (this.selected_scoring == 3) {
        this.group_scores.sort((a, b) =>
          a.stableford_total < b.stableford_total ? 1 : -1
        )
      }
      else if (this.selected_scoring == 4) {
        this.group_scores.sort((a, b) =>
          a.gross_score < b.gross_score ? 1 : -1
        )
      }
    },
  },
  mounted() {},
  methods: {
    scoringDescription() {
      if (this.selected_scoring == 1) {
        return 'Turnierart: Stableford'
      } else if (this.selected_scoring == 2) {
        return 'Turnierart: Stroke Play'
      } else if (this.selected_scoring == 3) {
        return 'Turnierart: Best Ball - Stableford'
      } else {
        return 'Turnierart: Best Ball - Stroke Play'
      }
    },
    courseHandicapForGroup(group, group_calc_score) {
      return Math.abs(
        Math.round(
          group_calc_score.groups[group.id].handicap *
                            (this.tournament.course.slope / 113) -
                            this.tournament.course.course_rating +
                            this.tournament.course.par
        )
      )
    },
    courseHandicapForUser(user, calc_score) {
      return Math.abs(
        Math.round(
          calc_score.users[user.id].handicap *
                            (calc_score.users[user.id].slope / 113) -
                            calc_score.users[user.id].course_rating +
                            this.tournament.course.par
        )
      )
    },
    stablefordPointsForDifference(difference) {
      if (difference >= 3) {
        return 5
      } else if (difference == 2) {
        return 4
      } else if (difference == 1) {
        return 3
      } else if (difference == 0) {
        return 2
      } else if (difference == -1) {
        return 1
      } else {
        return 0
      }
    },
  },
  apollo: {
    tournament: {
      query: gql`
                    query tournament($id: ID!) {
                        tournament(id: $id) {
                            id
                            count_type
                            play_type
                            group_size
                            name
                            start_date
                            end_date
                            groups {
                                id
                                handicap
                                name
                                starting_hole {
                                    id
                                    name
                                }
                                starting_at
                                users {
                                    id
                                    title_prefix
                                    first_name
                                    last_name
                                    title_suffix
                                    handicap
                                    pivot {
                                        is_admin
                                        handicap
                                        course_rating
                                        slope
                                        color
                                    }
                                }
                            }
                            location {
                                id
                                name
                                address_line_1
                                address_line_2
                                postal_code
                                city
                                state
                                country
                                latitude
                                longitude
                            }
                            course {
                                name
                                course_rating
                                slope
                                par
                            }
                            holes {
                                id
                                name
                                par
                                handicap_index
                                sort_order
                                plays(tournament_id: $id) {
                                    score
                                    user_id
                                    user {
                                        id
                                    }
                                }
                            }
                        }
                    }
                `,
      variables() {
        return {
          id: this.$props.id,
        }
      },
      error(error) {
        console.log(error.message)
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
