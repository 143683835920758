<template>
  <div
    v-if="errors && errors[name]"
    class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full mt-3 mb-3"
    role="alert"
  >
    <span
      v-for="error in errors[name]"
      :key="error"
      class="font-bold"
    >{{ error }}</span>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      default: []
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

