<template>
  <div class="flex flex-row flex-wrap justify-between">
    <div>
      <h2
        class="mb-1 text-2xl font-bold text-teal-700 uppercase dark:text-teal-500"
      >
        <i
          :class="icon"
          class="mr-1"
        ></i> {{ title }}
      </h2>
      <p
        class="block mb-2 text-sm font-semibold leading-tight tracking-wide text-gray-500 uppercase"
      >
        {{ subtitle }}
      </p>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  methods: {
    hasSlot (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    }
  },
}
</script>

<style lang="scss" scoped>
</style>