<template>
    <div>
        <table v-if="!$apollo.loading">
            <thead>
                <th>Id</th>
                <th>Name</th>
            </thead>
            <tbody>
                <div v-for="user in users.data" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                </div>
            </tbody>
        </table>
        <button @click="page++">{{ page }}</button>
    </div>

    
</template>

<script>
import gql from 'graphql-tag'

    export default {
        data() {
            return {
                page: 0,
                users: null
            }
        },
        apollo: {
            users: {
                query: gql`
                    query users($page: Int) {
                        users(first:1, page: $page) {
                            data {
                                id,
                                name,
                                email
                            },
                            paginatorInfo {
                                currentPage,
                                count,
                                perPage
                            }
                        }
                    }`,
                variables () {
                    return {
                        page: this.page,
                    }
                },
            } 
            
        }
    }
</script>
