<template>
  <div
    class="flex-wrap w-full p-4 bg-white rounded shadow md:flex dark:bg-gray-900"
  >
    <div class="w-full border-b-2">
      <h2
        class="mb-1 text-2xl font-bold text-teal-700 uppercase dark:text-teal-500"
      >
        <i class="mr-1 fas fa-golf-ball"></i> Meine aktuellen Turniere
      </h2>
      <p
        class="block mb-2 text-sm font-semibold leading-tight tracking-wide text-gray-500 uppercase"
      >
        Finden Sie hier Ihre aktuellen Turniere
      </p>
    </div>
    <div class="w-full">
      <spinner v-if="this.$apollo.queries.tournaments.loading"></spinner>
      <div
        id="tournaments"
        class="mt-5"
      >
        <div
          v-for="tournament in tournaments"
          :key="tournament.id"
          class="dark:text-white"
        >
          <h4 class="w-full mt-3 mb-3 text-xl font-bold">
            {{ tournament.name }}
          </h4>
          <div class="flex flex-wrap items-start my-2 text-sm md:flex-no-wrap">
            <div
              class="flex-grow w-full mb-1 text-sm leading-5 text-gray-900 sm:mb-3 md:w-auto dark:text-gray-100"
            >
              <div
                class="flex items-start text-sm leading-normal text-gray-900 dark:text-gray-100"
              >
                <svg
                  class="flex-shrink-0 w-5 h-5 mr-2 text-gray-700 dark:text-gray-100"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    class="heroicon-ui"
                    d="M8 7V5c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v2h4a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h4zm8 2H8v10h8V9zm2 0v10h2V9h-2zM6 9H4v10h2V9zm4-2h4V5h-4v2z"
                  />
                </svg>

                {{ tournament.holes.length }} Löcher<br>
                Scoring: {{ scoringDescription(tournament) }}
              </div>
            </div>
            <div
              class="flex-grow w-full mb-1 text-sm leading-5 text-gray-900 sm:mb-3 md:w-auto"
            >
              <div
                class="flex items-start text-sm leading-normal text-gray-900 dark:text-gray-100"
              >
                <svg
                  class="flex-shrink-0 w-5 h-5 mr-2 text-gray-700 dark:text-gray-100"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clip-rule="evenodd"
                  />
                </svg>
                Start: {{ tournament.start_date | moment('DD.MM.YYYY') }}<br />
                Ende: {{ tournament.end_date | moment('DD.MM.YYYY') }}
              </div>
            </div>
            <div
              class="flex-grow w-full mb-1 leading-5 text-gray-900 sm:mb-3 md:w-auto"
            >
              <div
                class="flex items-start text-sm leading-normal text-gray-900 dark:text-gray-100"
              >
                <svg
                  class="flex-shrink-0 w-5 h-5 mr-2 text-gray-700 dark:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clip-rule="evenodd"
                  />
                </svg>
                {{ tournament.location.name }}<br />
                {{ tournament.location.address_line_1 }}
                {{ tournament.location.address_line_2 }} <br />
                {{ tournament.location.postal_code }}
                {{ tournament.location.city }}
              </div>
            </div>
            <div
              class="flex-grow w-full mb-3 leading-5 text-gray-900 md:mb-4 md:w-auto"
            >
              <span
                v-if="tournament.status == 'ACTIVE'"
                class="inline-flex px-5 py-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 border rounded-full dark:bg-green-800 dark:text-gray-100"
              >
                Läuft
              </span>
              <span
                v-if="tournament.status == 'FINISHED'"
                class="inline-flex px-5 py-2 text-xs font-semibold leading-5 text-gray-800 bg-green-100 border rounded-full dark:bg-gray-800 dark:text-gray-100"
              >
                Beendet
              </span>
              <span
                v-if="tournament.status == 'NOT_STARTED'"
                class="inline-flex px-5 py-2 text-xs font-semibold leading-5 text-yellow-800 bg-green-100 border rounded-full dark:bg-yellow-800 dark:text-yellow-100"
              >
                Zukünftig
              </span>
            </div>
            <div
              class="flex-grow w-full pt-1 mb-1 leading-5 text-gray-900 sm:mb-3 md:w-auto"
            >
              <router-link
                :to="'/tournament/' + tournament.id"
                class="block w-full px-4 py-2 font-semibold text-center text-white transition duration-200 bg-green-600 rounded cursor-pointer md:w-auto dark:bg-teal-900 dark-hover:bg-teal-800 dark:text-white hover:bg-green-700"
              >
                <i class="mr-1 far fa-eye"></i>Turnier anzeigen
              </router-link>
              <router-link
                :to="'/tournament/' + tournament.id + '/scoreboard'"
                class="block w-full px-4 py-2 mt-2 font-semibold text-center text-white transition duration-200 bg-green-600 rounded cursor-pointer md:w-auto dark:bg-teal-900 dark-hover:bg-teal-800 dark:text-white hover:bg-green-700"
              >
                <i class="mr-1 fas fa-table"></i>Scoreboard
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import * as moment from 'moment'

export default {
  data() {
    return {
      tournaments: [],
      today: moment(),
      logged_user: window.logged_user
    }
  },
  methods: {
    scoringDescription(tournament) {
      if(tournament.play_type == 'STABLEFORD') {
        return 'Stableford'
      }
      else if(tournament.play_type == 'STROKE_PLAY'){
        return 'Stroke Play'
      }
      else if(tournament.play_type == 'BESTBALL'){
        return 'Best Ball'
      }
      else {
        return '-'
      }
    }
  },
  apollo: {
    tournaments: {
      query: gql`
        query tournaments($start_date: Date){
          tournaments: myTournaments (start_date: $start_date) {
            id
            name
            start_date
            end_date
            status
            location {
              id
              name
              address_line_1
              address_line_2
              postal_code
              city
              state
              country
            }
            holes {
              id
              name
            }
          }
        }
    `,
      variables() {
        return {
          start_date: this.today.subtract(30, 'days').format('YYYY-MM-DD'),
        }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
</style>