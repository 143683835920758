<template>
  <div>
    <div class="pt-5 mb-5">
      <SubHeader
        title="Gruppen"
        icon="fas fa-user-friends"
        subtitle="Hier sehen Sie Ihre Gruppe sowie eventuell weitere Gruppen, die an diesem Turnier teilnehmen."
      ></SubHeader>
    </div>

    <div
      v-if="tournament.groups"
      id="group-draggable"
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      <div
        v-for="group in tournament.groups"
        :key="group.id"
        :class="{ 'border-2': checkIfUserIsInGroup(group) }"
        class="p-3 mb-1 mr-3 bg-white rounded shadow dark:bg-gray-700 dark:text-gray-100 sm:mb-3"
      >
        <div class="flex justify-between w-full pt-1 pb-2 mb-3 font-bold border-b-2">
          <div>
            {{ group.name }}
          </div>
          <div>
            Handicap {{ handicapForGroup(group) }}
          </div>
        </div>
        <!-- Groups -->
        <draggable
          v-if="
            (logged_user.roles.includes('super-admin') ||
              checkIfUserIsAdminInGroup(group)) &&
              tournament.status == 'NOT_STARTED'
          "
          :id="group.id"
          :move="checkMove"
          class="p-1 pb-0 border-2 border-dashed"
          :list="group.users"
          :sort="true"
          group="users"
          @start="onStart"
          @end="onEnd"
          @change="groupsChanged"
        >
          <div
            v-for="user in group.users"
            :id="user.id"
            :key="user.name"
            :class="{ 'font-bold': logged_user.id == user.id }"
            class="grid grid-cols-2 p-1 mb-1 text-sm text-gray-600 border cursor-move md:p-3 dark:text-gray-100 lg:grid-cols-2"
          >
            <div class="flex">
              <div
                class="flex items-center content-center flex-shrink"
                aria-label="Group Admin"
                data-balloon-pos="up"
              >
                <i
                  v-if="user.pivot.is_admin"
                  class="mt-0 mr-1 fas fa-user-cog"
                ></i>
              </div>
              <div>
                {{ user.title_prefix }} {{ user.first_name }}
                {{ user.last_name }} {{ user.title_suffix }}
              </div>
            </div>
            <div class="text-right">
              Handicap: <span v-if="logged_user.id == user.id">{{ handicap }}</span>
              <span v-if="logged_user.id != user.id">{{ user.handicap }}</span>
            </div>
          </div>
        </draggable>
        <div
          v-for="user in group.users"
          v-else
          :key="user.id"
          class="grid grid-cols-2 mb-2 text-sm text-gray-600 dark:text-gray-100 lg:grid-cols-2"
        >
          <div>
            {{ user.title_prefix }} {{ user.first_name }} {{ user.last_name }}
            {{ user.title_suffix }}
          </div>
          <div class="text-right">
            Handicap: {{ user.handicap }}
          </div>
        </div>
        <!-- End Groups -->
        <!-- Invitations -->
        <div
          v-if="
            group.users.length < tournament.group_size &&
              group.invitations.length > 0 &&
              tournament.status == 'NOT_STARTED'
          "
        >
          <h5
            class="w-full mt-5 mb-4 text-lg font-bold text-teal-600 uppercase"
          >
            Invitations
          </h5>
          <div
            v-for="invitation in group.invitations"
            :key="invitation.id"
          >
            <div
              v-if="invitation.invited_user != null"
              class="grid grid-cols-2 p-1 mb-1 mb-2 text-sm text-gray-600 border md:p-3 dark:text-gray-100 lg:grid-cols-2"
            >
              <div>
                <div class="font-semibold">
                  {{ invitation.invited_user.title_prefix }}
                  {{ invitation.invited_user.first_name }}
                  {{ invitation.invited_user.last_name }}
                  {{ invitation.invited_user.title_suffix }}<br />
                </div>
                <div class="text-gray-500 dark:text-gray-200">
                  {{ invitation.invited_user.email }}
                </div>
              </div>
              <div class="text-right">
                <a
                  v-if="
                    logged_user.roles.includes('super-admin') ||
                      invitation.invited_by_user.id == logged_user.id ||
                      (invitation.invited_user != null &&
                        invitation.invited_user.id == logged_user.id)
                  "
                  class="inline-block w-10 p-2 mr-2 text-lg text-center border"
                  @click="deleteInvitation(group, invitation)"
                >
                  <i
                    class="text-red-500 transition duration-200 ease-in-out transform cursor-pointer fas fa-trash hover:scale-125"
                  ></i>
                </a>
                <a
                  v-if="
                    logged_user.roles.includes('super-admin') ||
                      invitation.invited_user.id == logged_user.id
                  "
                  class="inline-block w-10 p-2 text-lg text-center border"
                  @click="acceptInvitation(group, invitation)"
                >
                  <i
                    class="text-green-500 transition duration-200 ease-in-out transform cursor-pointer fas fa-check hover:scale-125"
                  ></i>
                </a>
              </div>
            </div>
            <div
              v-else
              class="grid grid-cols-2 p-1 mb-2 text-sm text-gray-600 border md:p-3 dark:text-gray-100 lg:grid-cols-2"
            >
              <div>
                <div class="font-semibold">
                  {{ invitation.first_name }} {{ invitation.last_name }}<br />
                </div>
                <div class="text-gray-500 dark:text-gray-200">
                  {{ invitation.email }}
                </div>
              </div>
              <div class="text-right">
                <a
                  v-if="
                    logged_user.roles.includes('super-admin') ||
                      invitation.invited_by_user.id == logged_user.id
                  "
                  @click="deleteInvitation(group, invitation)"
                >
                  <i
                    class="mt-2 mr-2 transition duration-200 ease-in-out transform cursor-pointer fas fa-trash hover:text-red-500 hover:scale-125"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Invitations -->
        <a
          v-if="
            (logged_user.roles.includes('super-admin') ||
              checkIfUserIsAdminInGroup(group)) &&
              group.users.length + group.invitations.length <
              tournament.group_size &&
              tournament.status == 'NOT_STARTED'
          "
          class="block w-full p-3 mt-2 text-sm font-bold text-center text-white uppercase transition duration-200 bg-teal-700 cursor-pointer hover:bg-teal-800"
          @click="createInvitation(group)"
        ><i class="fas fa-user-plus"></i> Invite Player</a>
      </div>
    </div>
    <modal
      name="invite-player"
      classes="bg-white dark:bg-gray-900 dark:text-white text-gray-100 p-3"
      height="auto"
      :adaptive="true"
      classses="transition duration-200"
    >
      <div class="flex">
        <h5
          class="flex-grow w-full mt-1 mb-4 text-lg font-bold text-teal-600 uppercase"
        >
          Invite Player
        </h5>
        <i
          class="flex-shrink float-right mt-1 mr-2 cursor-pointer fas fa-times"
          @click="closeInvitation()"
        ></i>
      </div>

      <text-input
        v-model="invite_first_name"
        :errors="errors"
        required
        name="invite_first_name"
        label="Vorname"
      ></text-input>
      <text-input
        v-model="invite_last_name"
        :errors="errors"
        required
        name="invite_last_name"
        label="Nachname"
      ></text-input>
      <text-input
        v-model="invite_email"
        type="email"
        :errors="errors"
        required
        name="invite_email"
        label="E-Mail"
      ></text-input>
      <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <a
          :disabled="saving"
          class="block w-full p-3 mt-0 text-sm font-bold text-center text-white uppercase transition duration-200 bg-teal-700 cursor-pointer disabled:opacity-75 md:mt-2 hover:bg-teal-800"
          @click="sendInvitation()"
        ><i class="fas fa-paper-plane"></i> Send Invitation</a>
        <a
          class="block w-full p-2 p-3 mt-0 text-sm font-bold text-center text-gray-800 uppercase transition duration-200 border border-gray-500 cursor-pointer md:mt-2 dark:text-white focus:outline-none focus:shadow-solid"
          @click="closeInvitation()"
        ><i class="fas fa-times"></i> Cancel</a>
      </div>
    </modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'
import gql from 'graphql-tag'
import Noty from 'noty'
import SubHeader from '../ui/SubHeader'

export default {
  components: {
    draggable,
    SubHeader
  },
  props: {
    tournament: {
      type: Object,
      default: null,
    },
    handicap: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected_group: null,
      logged_user: window.logged_user,
      invite_first_name: '',
      invite_last_name: '',
      invite_email: '',
      saving: false,
      errors: null,
      from_group_id: null,
      to_group_id: null,
    }
  },
  methods: {
    handicapForGroup(group) {
      var handicap = 0
      group.users.forEach((user) => {
        handicap += user.handicap
      })

      if(group.users.length == 0) {
        return 0
      }
      return (handicap / group.users.length).toFixed(2)
    },
    checkIfUserIsInGroup(group) {
      var foundUser = group.users.find(user => user.id == window.logged_user.id)
      if (typeof foundUser === 'undefined') {
        return false
      } else {
        return true
      }
    },
    buildGraqhQueryForGroup(group) {
      var userQuery = ''
      var index = 0

      group.users.forEach(user => {
        userQuery += `
          {
            id: ${user.id},
            is_admin: ${user.pivot.is_admin},
            sort_order: ${index}
          },
        `
        index++
      })

      var query = gql`
            mutation {
              updateGroup(
                input: {
                  id: ${group.id}
                  users: { sync: [${userQuery}] }
                }
              ) {
                id
                name
                users {
                  id
                  first_name
                  last_name
                  pivot {
                    is_admin
                  }
                }
              }
            }

      `

      console.log(query)

      return query
    },
    onStart: function(evt) {
      this.from_group_id = evt.from.id
      console.log(evt)
    },
    onEnd: function(evt) {
      this.to_group_id = evt.to.id

      var groupsToSave = []

      var newGroup = this.tournament.groups.find(
        group => group.id == evt.to.id
      )

      if(typeof newGroup !== 'undefined' && newGroup != null && newGroup != {}) {
        groupsToSave.push(newGroup)
      }

      var oldGroup = this.tournament.groups.find(
        group => group.id == evt.from.id
      )

      if(typeof oldGroup !== 'undefined' && oldGroup != null && oldGroup != {}) {
        groupsToSave.push(oldGroup)
      }

      groupsToSave.forEach(group => {
        var query = this.buildGraqhQueryForGroup(group)

        this.$apollo
          .mutate({
            // Query
            mutation: query,
          })
          .then(data => {
            new Noty({
              type: 'success',
              text: group.name + ' erfolgreich aktualisiert',
              timeout: 2000,
              progressBar: true
            }).show()
          })
          .catch(error => {
            new Noty({
              type: 'alert',
              text: 'Fehler beim Speichern von ' + group.name
            }).show()
          })
      })

    },
    groupsChanged: function() {},
    checkMove: function(evt) {
      if (evt.to.__vue__.list.length == this.tournament.group_size) {
        return false
      } else {
        return true
      }
    },
    checkIfUserIsAdminInGroup(group) {
      var foundUser = group.users.find(user => user.id == window.logged_user.id)

      if (typeof foundUser !== 'undefined' && foundUser.pivot.is_admin) {
        return true
      }

      return false
    },
    checkForm() {
      var success = true

      if (this.invite_first_name == '') {
        this.errors.invite_first_name = ['Vorname muss ausgefüllt werden.']
        success = false
      }
      if (this.invite_last_name == '') {
        this.errors.invite_last_name = ['Nachname muss ausgefüllt werden.']
        success = false
      }
      if (this.invite_email == '') {
        this.errors.invite_email = ['Email muss ausgefüllt werden.']
        success = false
      }

      return success
    },
    sendInvitation() {
      this.errors = {}

      if (!this.checkForm()) {
        return
      }

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation(
              $tournament_id: ID!
              $group_id: ID
              $first_name: String!
              $last_name: String!
              $email: String!
            ) {
              inviteUserToTournament(
                tournament_id: $tournament_id
                group_id: $group_id
                first_name: $first_name
                last_name: $last_name
                email: $email
              ) {
                id
                invited_user {
                  id
                  title_prefix
                  title_suffix
                  handicap
                  first_name
                  last_name
                  email
                }
                invited_by_user {
                  id
                }
                first_name
                last_name
                email
              }
            }
          `,
          // Parameters
          variables: {
            tournament_id: this.tournament.id,
            group_id: this.selected_group.id,
            first_name: this.invite_first_name,
            last_name: this.invite_last_name,
            email: this.invite_email,
          },
        })
        .then(data => {
          Swal.fire({
            title: 'Einladung versandt',
            text: 'Die Einladung wurde erfolgreich versandt',
            timerProgressBar: true,
            icon: 'success',
            position: 'center',
          })

          this.closeInvitation()

          this.selected_group.invitations.push(data.data.inviteUserToTournament)
          this.saving = false
          this.errors = null
        })
        .catch(error => {
          Swal.fire({
            title: 'Fehler',
            text:
              'Beim Versenden der Einladung ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben',
            timerProgressBar: true,
            icon: 'error',
            position: 'center',
          })

          console.log(error)

          this.saving = false
        })
    },
    closeInvitation() {
      this.invite_first_name = ''
      this.invite_last_name = ''
      this.invite_email = ''

      this.errors = {}

      this.$modal.hide('invite-player')
    },
    createInvitation(group) {
      this.selected_group = group
      this.$modal.show('invite-player')
    },
    acceptInvitation(group, invitation) {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($id: ID!) {
              acceptTournamentGroupInvitation(id: $id) {
                id
                name
                users {
                  pivot {
                    is_admin
                  }
                  id
                  title_prefix
                  first_name
                  last_name
                  title_suffix
                  handicap
                }
                invitations {
                  id
                  invited_user {
                    title_prefix
                    title_suffix
                    handicap
                    first_name
                    last_name
                    email
                  }
                  invited_by_user {
                    id
                  }
                  first_name
                  last_name
                  email
                }
              }
            }
          `,
          // Parameters
          variables: {
            id: invitation.id,
          },
        })
        .then(data => {
          Swal.fire({
            title: 'Einladung angenommen',
            text: 'Die Einladung wurde erfolgreich angenommen',
            timerProgressBar: true,
            icon: 'success',
            position: 'center',
          })

          group = data
        })
        .catch(error => {
          Swal.fire({
            title: 'Fehler',
            text: 'Beim Annehmen der Einladung ist ein Fehler aufgetreten.',
            timerProgressBar: true,
            icon: 'error',
            position: 'center',
          })
        })
    },
    deleteInvitation(group, invitation) {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($id: ID!) {
              deleteInvitation(id: $id) {
                id
              }
            }
          `,
          // Parameters
          variables: {
            id: invitation.id,
          },
        })
        .then(data => {
          Swal.fire({
            title: 'Einladung gelöscht',
            text: 'Die Einladung wurde erfolgreich gelöscht',
            timerProgressBar: true,
            icon: 'success',
            position: 'center',
          })

          group.invitations = group.invitations.filter(function(el) {
            return el.id != invitation.id
          })
        })
        .catch(error => {
          Swal.fire({
            title: 'Fehler',
            text: 'Beim Löschen der Einladung ist ein Fehler aufgetreten.',
            timerProgressBar: true,
            icon: 'error',
            position: 'center',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>