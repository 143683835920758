<template>
  <div>
    <div
      v-if="this.$apollo.queries.tournament.loading"
      class="container mx-auto"
    >
      <spinner></spinner>
    </div>

    <page-header
      v-if="tournament != null && !this.$apollo.queries.tournament.loading"
      :title="tournament.name.toUpperCase()"
      :subtitle="scoringDescription()"
    ></page-header>
    <page-header
      v-if="tournament == null && !this.$apollo.queries.tournament.loading"
      title="Turnier nicht gefunden"
      subtitle="Kein Tunier mit dieser ID vorhanden."
    ></page-header>

    <div class="bg-white dark:bg-gray-900">
      <div class="container mx-auto">
        <nav class="flex">
          <router-link
            to="#holes"
            :class="{
              'border-b-2 font-medium border-teal-500 text-teal-500':
                active_tab == 1,
            }"
            class="block px-2 py-2 text-gray-600 cursor-pointer md:py-4 md:px-6 hover:text-teal-500 focus:outline-none"
            @click.native="active_tab = 1"
          >
            <i class="fas fa-golf-ball"></i> Löcher / Scoring
          </router-link>
          <router-link
            to="#groups"
            :class="{
              'border-b-2 font-medium border-teal-500 text-teal-500':
                active_tab == 2,
            }"
            class="block px-2 py-2 text-gray-600 cursor-pointer md:py-4 md:px-6 hover:text-teal-500 focus:outline-none"
            @click.native="active_tab = 2"
          >
            <i class="fas fa-user-friends"></i> Gruppen
          </router-link>
          <router-link
            to="#location"
            :class="{
              'border-b-2 font-medium border-teal-500 text-teal-500':
                active_tab == 3,
            }"
            class="block px-2 py-2 text-gray-600 cursor-pointer md:py-4 md:px-6 hover:text-teal-500 focus:outline-none"
            @click.native="active_tab = 3"
          >
            <i class="fas fa-map-marker"></i> Golfplatz
          </router-link>
        </nav>
      </div>
    </div>


    <div class="container mx-auto">
      <div
        v-if="tournament != null"
        class="gap-4 p-3 mt-4 bg-white border-b-2 rounded shadow lg:grid lg:grid-cols-2 dark:bg-gray-700 dark:text-gray-100"
      >
        <div>
          <h3 class="mb-1 text-2xl font-bold text-teal-700 uppercase dark:text-teal-500">
            Handicap Settings
          </h3>
          <p
            v-if="play_count == 0"
            class="block mb-2 text-sm font-semibold leading-tight tracking-wide text-gray-500 uppercase"
          >
            Bitte bestätigen Sie hier nochmals Ihr Handicap.
          </p>
          <p
            v-if="play_count == 0"
            class="block mb-2 text-sm font-semibold leading-tight tracking-wide text-red-500 uppercase"
          >
            Überprüfen Sie dieses bevor Sie Ihren ersten Schlag speichern. Danach ist eine Änderung nicht mehr möglich.
          </p>
          <p
            v-if="play_count > 0"
            class="block mb-2 text-sm font-semibold leading-tight tracking-wide text-gray-500 uppercase"
          >
            Ihr für dieses Turnier eingetragenes Handicap
          </p>
          <number-input
            v-model="handicap"
            :errors="null"
            name="handicap"
            step="0.1"
            :readonly="play_count > 0"
            min="-54"
            max="5"
            label="Ihr Handicap (typischerweise < 0)"
          ></number-input>
          <p
            v-if="handicap > 5"
            class="block p-4 mb-2 text-sm font-semibold leading-tight tracking-wide text-red-500 uppercase border-red-300"
          >
            Haben Sie ganz sicher das richtige Handicap eingetragen?
          </p>
        </div>
        <!-- <div>
          <div class="w-full">
            <h3 class="mb-1 text-2xl font-bold text-teal-700 uppercase dark:text-teal-500">
              Tee Auswahl
            </h3>
            <select
              v-model="selected_tee"
              name="selected_tee"
              class="w-full p-3 text-gray-200 bg-gray-900 border border-gray-500 rounded appearance-none"
            >
              <option disabled>
                Tee
              </option>
              <option
                v-for="(tee, index) in tournament.course.tees"
                :key="tee.id"
                :value="tee"
              >
                {{ tee.name }} {{ tee.slope }} / {{ tee.course_rating }}
              </option>
            </select>
          </div>
        </div> -->
        <div>
          <h3 class="mb-1 text-2xl font-bold text-teal-700 uppercase dark:text-teal-500">
            Kurs Rating
          </h3>
          <p class="block mb-2 text-sm font-semibold leading-tight tracking-wide text-gray-500 uppercase">
            Course Rating: {{ course_rating }}
          </p>
          <p class="block mb-2 text-sm font-semibold leading-tight tracking-wide text-gray-500 uppercase">
            Slope: {{ slope }}
          </p>
          <hr
            class="w-full mb-2 dark:bg-gray-600"
            style="height: 1px;"
          >
          <h3 class="mb-1 text-2xl font-bold text-teal-700 uppercase dark:text-teal-500">
            Ihre Spielvorgabe: {{ course_handicap }}
          </h3>
        </div>
      </div>
      <div
        v-if="active_tab == 1"
        key="1"
      >
        <div
          v-if="tournament != null && !this.$apollo.queries.tournament.loading"
          class="mt-3"
        >
          <tournament-detail-holes
            :handicap="handicap"
            :tournament="tournament"
            :course-handicap="course_handicap"
          ></tournament-detail-holes>
        </div>
      </div>

      <div
        v-if="active_tab == 2"
        key="2"
      >
        <div
          v-if="tournament != null && !this.$apollo.queries.tournament.loading"
          class="ml-2 md:ml-0"
        >
          <tournament-detail-groups
            :tournament="tournament"
            :handicap="handicap"
          ></tournament-detail-groups>
        </div>
      </div>

      <keep-alive>
        <div
          v-if="active_tab == 3"
          key="3"
        >
          <div
            v-if="tournament != null && !this.$apollo.queries.tournament.loading"
            class="mt-3"
          >
            <tournament-detail-location
              :tournament="tournament"
            ></tournament-detail-location>
          </div>
        </div>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import _ from 'lodash'

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected_tee: null,
      active_tab: 1,
      tournament: null,
      logged_user: window.logged_user,
      handicap: null,
      course_rating: 0,
      par: 0,
      slope: 0,
      color: null
    }
  },
  computed: {
    course_handicap() {
      return Math.abs(Math.round(
        this.handicap * (this.slope / 113) -
          this.course_rating +
          this.par
      ))
    },
    play_count() {
      var count = 0

      this.tournament.holes.forEach(hole => {
        hole.plays.forEach(play => {
          count++
        })
      })

      return count
    }
  },
  watch: {
    selected_tee(val){
      console.log(this.selected_tee)
      this.slope = this.selected_tee.slope
      this.par = this.selected_tee.par
      this.course_rating = this.selected_tee.course_rating
      this.calculateStrokes()
    },
    handicap(val) {
      this.calculateStrokes()
    },
    tournament() {
      var that = this

      this.tournament.groups.forEach(group => {
        group.users.forEach(user => {
          if(user.id == this.logged_user.id) {
            if(user.pivot.handicap) {
              that.handicap = user.pivot.handicap
            }
            if(user.pivot.color) {
              that.color = user.pivot.color
              that.course_rating = user.pivot.course_rating
              that.slope = user.pivot.slope
            }
          }


        })
      })

      if(!this.handicap) {
        this.handicap = this.logged_user.handicap
      }

      if(!this.color) {
        if(this.logged_user.salutation == 1) {
          this.course_rating = this.tournament.course.default_tee_men.course_rating
          this.slope = this.tournament.course.default_tee_men.slope
          this.par = this.tournament.course.default_tee_men.par
        }
        else {
          this.course_rating = this.tournament.course.default_tee_women.course_rating
          this.slope = this.tournament.course.default_tee_women.slope
          this.par = this.tournament.course.default_tee_women.par
        }
      }

      if(this.par == 0) {
        this.par = this.tournament.course.par
      }


      this.calculateStrokes()

      var starting_hole = null
      var starting_date = null

      for (var i = 0; i < this.tournament.groups.length; i++) {
        for (var j = 0; j < this.tournament.groups[i].users.length; j++) {
          if (
            this.tournament.groups[i].users[j].id == this.logged_user.id &&
            this.tournament.groups[i].starting_hole
          ) {
            starting_hole = this.tournament.groups[i].starting_hole
            starting_date = this.tournament.groups[i].starting_at
          }
        }
      }

      this.tournament.holes = this.tournament.holes.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1)

      if (starting_hole) {

        var index = this.tournament.holes.findIndex(
          x => x.id === starting_hole.id
        )

        if (index) {
          var before_holes = this.tournament.holes.slice(0, index)
          this.tournament.holes = this.tournament.holes
            .slice(index)
            .concat(before_holes)
        }

        this.tournament.holes[0]['is_starting'] = true
        if(starting_date) {
          this.tournament.holes[0]['start_date'] = starting_date
        }

      }

    },
  },
  mounted() {
    if (this.$route.hash == '#holes') {
      this.active_tab = 1
    } else if (this.$route.hash == '#groups') {
      this.active_tab = 2
    } else if (this.$route.hash == '#location') {
      this.active_tab = 3
    }
  },
  methods: {
    scoringDescription() {
      if (this.tournament.play_type == 'STABLEFORD') {
        return 'Turnierart: Stableford'
      }
      else if (this.tournament.play_type == 'STROKE_PLAY') {
        return 'Turnierart: Stroke Play'
      }
      else if (this.tournament.play_type == 'BESTBALL_STABLEFORD') {
        return 'Turnierart: Best Ball - Stableford'
      }
      else {
        return 'Turnierart: Best Ball - Stroke Play'
      }

    },
    calculateStrokes() {

      var extra_strokes = this.course_handicap

      var handicap_holes = [...this.tournament.holes]
      handicap_holes.sort((a, b) => (a.handicap_index > b.handicap_index) ? 1 : -1)

      handicap_holes.forEach(hole => {
        hole['extra_strokes'] = 0
        hole['handicap_par'] = hole.par
      })

      if (this.tournament.play_type == 'STABLEFORD' || this.tournament.play_type == 'BESTBALL_STABLEFORD') {
        do {
          handicap_holes.forEach(hole => {

            if(extra_strokes > 0) {
              hole['extra_strokes']++
              hole['handicap_par']++
            }

            extra_strokes--

          })
        }
        while (extra_strokes > 0)

        handicap_holes.forEach(handicap_hole => {
          this.tournament.holes.forEach(hole => {
            if(handicap_hole.id == hole.id) {
              hole['extra_strokes'] = handicap_hole.extra_strokes
              hole['handicap_par'] = handicap_hole.handicap_par

            }
          })
        })
      }
      else {
        handicap_holes.forEach(hole => {

          if(extra_strokes > 0) {
            hole['extra_strokes'] = 0
            hole['handicap_par'] = hole.par
          }

          extra_strokes--

        })
      }



    }
  },
  apollo: {
    tournament: {
      query: gql`
        query tournament($id: ID!, $user_id: ID) {
          tournament(id: $id) {
            id
            count_type
            play_type
            group_size
            status
            name
            start_date
            end_date
            groups {
              id
              name
              starting_hole {
                id
                name
              }
              starting_at
              users {
                pivot {
                  is_admin
                  handicap
                  color
                  slope
                  course_rating
                }
                id
                title_prefix
                first_name
                last_name
                title_suffix
                handicap
              }
              invitations {
                id
                invited_user {
                  title_prefix
                  title_suffix
                  handicap
                  first_name
                  last_name
                  email
                }
                invited_by_user {
                  id
                }
                first_name
                last_name
                email
              }
            }
            location {
              id
              name
              address_line_1
              address_line_2
              postal_code
              city
              state
              country
              latitude
              longitude
            }
            course {
              name
              course_rating
              slope
              par
              default_tee_men {
                name,
                color,
                slope,
                par,
                course_rating
              }
              default_tee_women {
                name,
                color,
                slope,
                par,
                course_rating
              }
              tees {
                name,
                color,
                slope,
                par,
                course_rating
              }
            }
            holes {
              id
              name
              par
              handicap_index
              sort_order
              plays(tournament_id: $id, user_id: $user_id) {
                score
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$props.id,
          user_id: window.logged_user.id,
        }
      },
      error(error) {
        console.log(error.message)
      },
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}

.vue-slider-process,
.vue-slider-dot-tooltip-inner {
  background-color: #319795;
}

.vue-slider-dot-tooltip-text {
  font-size: 16px;
  font-weight: bold;
}
</style>