<template>
  <div>
    <div class="pt-4 mb-4">
      <SubHeader
        title="Golfplatz"
        icon="fas fa-map-marker"
        subtitle="Hier finden Sie Informationen zum Golfplatz und dem Kurs."
      ></SubHeader>
    </div>

    <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
      <div>
        <div
          id="location-info"
          class="p-3 mt-3 bg-white rounded shadow dark:bg-gray-700 dark:text-gray-100"
        >
          <h3 class="text-xl font-bold uppercase">
            {{ tournament.location.name }}
          </h3>
          <div
            class="mt-1 text-sm leading-normal text-gray-600 dark:text-gray-200"
          >
            {{ tournament.location.address_line_1 }}
            {{ tournament.location.address_line_2 }}<br />
            {{ tournament.location.postal_code }} {{ tournament.location.city
            }}<br />
            {{ tournament.location.state }}<br />
          </div>
        </div>

        <div
          id="course-info"
          class="p-3 mt-3 bg-white rounded shadow dark:bg-gray-700 dark:text-gray-100"
        >
          <h3 class="text-xl font-bold uppercase">
            {{ tournament.course.name }}
          </h3>
          <div
            class="mt-1 text-sm leading-normal text-gray-600 dark:text-gray-200"
          >
            Course Rating: {{ tournament.course.course_rating }}<br>
            Slope: {{ tournament.course.slope }}<br>
            Par: {{ tournament.course.par }}<br>
          </div>
        </div>
      </div>


      <div
        id="location-map"
        style="min-height: 500px;"
        class="col-span-1 p-3 mt-3 bg-white rounded shadow dark:bg-gray-700 lg:col-span-2"
      >
        <MglMap
          access-token="pk.eyJ1Ijoic3ZlbnNvbWVkaWEiLCJhIjoiY2p1OWpubXd0MWNlazRkbXN5czZpN3Y1eiJ9.2sAxp5C5_C2nHrcScZiJHw"
          :map-style="getLayerStyle()"
          :center="[tournament.location.longitude, tournament.location.latitude]"
          :zoom="14"
        >
          <MglMarker
            :coordinates="[
              tournament.location.longitude,
              tournament.location.latitude,
            ]"
            color="blue"
          />
        </MglMap>
      </div>
    </div>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl'
import { MglMap, MglMarker } from 'vue-mapbox'
import SubHeader from '../ui/SubHeader'

export default {
  components: {
    SubHeader,
    MglMap,
    MglMarker,
  },
  props: {
    tournament: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.mapbox = Mapbox
  },
  methods: {
    getLayerStyle() {
      if (document.body.classList.contains('mode-dark')) {
        return 'mapbox://styles/mapbox/dark-v10'
      } else {
        return 'mapbox://styles/mapbox/outdoors-v11'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>