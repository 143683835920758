<template>
  <div
    class="md:flex w-full flex-wrap rounded bg-white dark:bg-gray-900 shadow p-4"
  >
    <div class="w-full border-b-2">
      <h2
        class="uppercase text-2xl mb-1 text-teal-700 dark:text-teal-500 font-bold"
      >
        <i class="fas fa-envelope mr-1"></i> Meine Einladungen
      </h2>
      <p
        class="block mb-2 uppercase text-sm leading-tight tracking-wide font-semibold text-gray-500"
      >
        Finden Sie hier alle Tuniere, zu denen Sie eingeladen wurden
      </p>
    </div>
    <div class="w-full">
      <spinner v-if="this.$apollo.queries.invitations.loading"></spinner>
      <div
        v-if="invitations.length == 0"
        class="mt-3 dark:text-white text-gray-100"
      >
        Momentan keine Einladungen vorhanden.
      </div>
      <div
        id="invitations"
        class="mt-5"
      >
        <div
          v-for="invitation in invitations"
          :key="invitation.id"
          class="dark:text-white"
        >
          <h4 class="text-xl font-bold w-full mb-3 mt-3">
            {{ invitation.tournament.name }}
          </h4>
          <div class="flex items-start my-2 flex-wrap md:flex-no-wrap text-sm">
            <div
              class="flex-grow mb-1 sm:mb-3 w-full md:w-auto text-sm leading-5 text-gray-900 dark:text-gray-100"
            >
              <div
                class="flex text-sm leading-normal text-gray-900  dark:text-gray-100"
              >
                <svg
                  class="flex-shrink-0 mr-2 h-5 w-5 dark:text-white text-gray-700"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    class="heroicon-ui"
                    d="M8 7V5c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v2h4a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h4zm8 2H8v10h8V9zm2 0v10h2V9h-2zM6 9H4v10h2V9zm4-2h4V5h-4v2z"
                  />
                </svg>

                {{ invitation.tournament.holes.length }} Löcher
              </div>
            </div>
            <div
              class="flex-grow mb-1 sm:mb-3 w-full md:w-auto text-sm leading-5 text-gray-900"
            >
              <div
                class="flex text-sm leading-normal text-gray-900 dark:text-gray-100"
              >
                <svg
                  class="flex-shrink-0 mr-2 h-5 w-5 dark:text-white text-gray-700"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clip-rule="evenodd"
                  />
                </svg>
                Start: {{ invitation.tournament.start_date | moment('DD.MM.YYYY') }}<br />
                Ende: {{ invitation.tournament.end_date | moment('DD.MM.YYYY') }}
              </div>
            </div>
            <div
              class="flex-grow mb-1 sm:mb-3 w-full md:w-auto leading-5 text-gray-900"
            >
              <div
                class="flex leading-normal text-sm text-gray-900 dark:text-gray-100"
              >
                <svg
                  class="flex-shrink-0 mr-2 h-5 w-5 dark:text-white text-gray-700"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clip-rule="evenodd"
                  />
                </svg>
                {{ invitation.tournament.location.name }}<br />
                {{ invitation.tournament.location.address_line_1 }}
                {{ invitation.tournament.location.address_line_2 }} <br />
                {{ invitation.tournament.location.postal_code }}
                {{ invitation.tournament.location.city }}
              </div>
            </div>
            <div
              class="flex-grow mb-3 md:mb-4 w-full md:w-auto leading-5 text-gray-900"
            >
              <span
                class="inline-flex text-xs px-5 py-2 border leading-5 dark:bg-green-800 dark:text-gray-100 font-semibold rounded-full bg-green-100 text-green-800"
              >
                Active
              </span>
            </div>
            <div
              class="flex-grow mb-1 pt-1 sm:mb-3 w-full md:w-auto leading-5 text-gray-900"
            >
              <router-link
                :to="'/tournament/' + invitation.tournament.id + '/#groups'"
                class="text-white block text-center transition duration-200 w-full md:w-auto cursor-pointer dark:bg-teal-900 dark-hover:bg-teal-800 dark:text-white hover:bg-green-700 font-semibold py-2 px-4 bg-green-600 rounded"
              >
                <i class="far fa-eye mr-1"></i>Turnier anzeigen
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  data() {
    return {
      invitations: [],
    }
  },
  apollo: {
    invitations: {
      query: gql`query invitations($invited_user_id: ID!) {
                    invitationsForUser(invited_user_id: $invited_user_id) {
                        id,
                        invited_user {
                            title_prefix,
                            title_suffix,
                            handicap,
                            first_name,
                            last_name,
                            email
                        },
                        invited_by_user {
                            id
                        }
                        first_name,
                        last_name,
                        email,
                        tournament {
                            id,
                            count_type,
                            play_type,
                            group_size,
                            name,
                            start_date,
                            end_date,
                            location {
                                id,
                                name, 
                                address_line_1,
                                address_line_2,
                                postal_code,
                                city,
                                state,
                                country,
                                latitude, 
                                longitude
                            }
                            holes {
                                id,
                                name,
                                par,
                                handicap_index
                            }
                        },
                      
                    }
                }`,
      update: data => data.invitationsForUser,
      variables () {
        return {
          invited_user_id: window.logged_user.id
        }
      },
      error(error) {
        console.log(error.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>