require('./bootstrap')

import ApolloClient from 'apollo-client'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import Vuelidate from 'vuelidate'
import VueRouter from 'vue-router'
import {routes} from './routes.js'
import VueMoment from 'vue-moment'
import VueNumberInput from '@chenfengyuan/vue-number-input'
import vmodal from 'vue-js-modal'



let token = document.head.querySelector('meta[name="csrf-token"]')
 

const httpLink = new HttpLink({
  headers: {
    'X-CSRF-TOKEN': token.content,
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json'
  }
})

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  // We log every GraphQL errors
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }
  // When a 401 error occur, we log-out the user.
  if (networkError) {
    if (networkError.statusCode === 401) {
      document.getElementById('logout-form').submit()
    }
  }
})

const cache = new InMemoryCache()

const apolloClient = new ApolloClient({
  link: onErrorLink.concat(httpLink),
  cache,
})

if (process.env.MIX_APP_ENV === 'production') {
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true 
}
else {
  Vue.config.productionTip = false
  Vue.config.devtools = true
}



Vue.component(
  'users',
  require('./components/admin/Users.vue').default
)

Vue.component(
  'spinner',
  require('./components/ui/Spinner.vue').default
)

Vue.component(
  'validation-error',
  require('./components/ui/ValidationError.vue').default
)

Vue.component(
  'text-input',
  require('./components/ui/TextInput.vue').default
)

Vue.component(
  'number-input',
  require('./components/ui/NumberInput.vue').default
)

Vue.component(
  'navigation',
  require('./components/ui/Navigation.vue').default
)

Vue.component(
  'page-header',
  require('./components/ui/Header.vue').default
)

Vue.component(
  'tournament-list-card',
  require('./components/tournament/TournamentListCard.vue').default
)

Vue.component(
  'tournament-invitations-list-card',
  require('./components/tournament/TournamentInvitationsListCard.vue').default
)

Vue.component(
  'tournament-detail-holes',
  require('./components/tournament/TournamentDetailHoles.vue').default
)

Vue.component(
  'tournament-detail-groups',
  require('./components/tournament/TournamentDetailGroups.vue').default
)

Vue.component(
  'tournament-detail-location',
  require('./components/tournament/TournamentDetailLocation.vue').default
)

Vue.use(VueApollo)
Vue.use(Vuelidate)
Vue.use(VueRouter)
Vue.use(VueMoment)
Vue.use(VueNumberInput)
Vue.use(vmodal)


const router = new VueRouter({
  routes // short for `routes: routes`
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

export const app = new Vue({
  el: '#app',
  router,
  apolloProvider,
})

