import Profile from './components/user/Profile.vue'
import Dashboard from './components/dashboard/Dashboard.vue'
import TournamentDetail from './components/tournament/TournamentDetail.vue'
import ScoreboardDetail from './components/scoreboard/ScoreboardDetail.vue'

export const routes = [
  { path: '/', component: Dashboard },
  { path: '/profile', component: Profile },
  { path: '/tournament/:id', component: TournamentDetail, props: true },
  { path: '/tournament/:id/scoreboard', component: ScoreboardDetail, props: true }
]
