<template>
  <nav
    class="bg-teal-900"
    @keydown.esc="open = false"
  >
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <a
              href="/"
              class="text-lg font-semibold text-gray-100 no-underline"
            >
              Golf App 
            </a>
          </div>
          <div
            v-if="loggedIn"
            class="hidden md:block"
          >
            <div class="ml-10 flex items-baseline desktop-menu">
              <router-link
                class="ml-4 px-3 py-2 display-block rounded-md text-md font-medium text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-700"
                to="/"
              >
                Dashboard
              </router-link>
            </div>
          </div>
        </div>
        <div
          v-if="loggedIn"
          class="hidden md:block"
        >
          <div class="ml-4 flex items-center md:ml-6">
            <div
              class="ml-3 relative"
              @clickaway="profile_open = false"
            >
              <div>
                <button
                  class="border-white border rounded p-3 text-white focus:outline-none focus:shadow-solid"
                  @click="profile_open = !profile_open"
                >
                  {{ userName }}
                </button>
              </div>
              <div
                v-show="profile_open"
                x-transition:enter-start="transform opacity-0 scale-95"
                x-transition:enter-end="transform opacity-100 scale-100"
                x-transition:leave="transition ease-in duration-75"
                x-transition:leave-start="transform opacity-100 scale-100"
                x-transition:leave-end="transform opacity-0 scale-95"
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
              >
                <div class="py-1 rounded-md bg-white shadow-xs">
                  <router-link
                    class="block px-4 py-2 text-sm text-gray-700 bg-white hover:bg-gray-200"
                    to="/profile"
                  >
                    Profile
                  </router-link>
                  <a
                    href="/logout"
                    class="block px-4 py-2 text-sm text-gray-700 bg-white hover:bg-gray-200"
                    onclick="event.preventDefault();
                                    document.getElementById('logout-form').submit();"
                  >Logout</a>
                  <form
                    id="logout-form"
                    action="/logout"
                    method="POST"
                    class="hidden"
                  >
                    <input
                      type="hidden"
                      name="_token"
                      :value="csrf"
                    />
                  </form>
                  <a
                    v-if="isAdmin"
                    href="/nova"
                    class="block px-4 py-2 text-sm text-gray-700 bg-white hover:bg-gray-200"
                    target="_blank"
                  >Admin</a>
                  <div class="mb-6">
                    <div class="md:w-1/3"></div>
                    <label
                      class="w-full block px-4 py-2 text-sm text-gray-700 bg-white text-gray-400"
                    >
                      <input
                        v-model="
                          dark_mode"
                        class="mr-2 leading-tight"
                        type="checkbox"
                      >
                      <span class="text-sm">
                        Dark Mode
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <button
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            @click="open = !open"
          >
            <svg
              class="h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                :class="{ hidden: open, 'inline-flex': !open }"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
              <path
                :class="{ hidden: !open, 'inline-flex': open }"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="loggedIn"
      :class="{ block: open, hidden: !open }"
      class="md:hidden mobile-menu"
    >
      <div class="px-2 pt-2 pb-3 sm:px-3">
        <router-link
          class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          to="/"
        >
          Dashboard
        </router-link>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-700">
        <div class="flex items-center px-3">
          <div class="ml-3">
            <div class="text-base font-medium leading-none text-white">
              {{ userName }}
            </div>
            <div class="mt-1 text-sm font-medium leading-none text-gray-400">
              {{ email }}
            </div>
          </div>
        </div>
        <div class="mt-3 px-2">
          <router-link
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            to="/profile"
          >
            Profile
          </router-link>
          <a
            href="/logout"
            class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            onclick="event.preventDefault();
                document.getElementById('logout-form').submit();"
          >Logout</a>
          <a
            v-if="isAdmin"
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            href="/nova"
            target="_blank"
          >Admin</a>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3"></div>
            <label
              class="w-full block px-3 py-2 rounded-md text-base font-medium text-gray-400"
            >
              <input
                v-model="
                  dark_mode"
                class="mr-2 leading-tight"
                type="checkbox"
              >
              <span class="text-sm">
                Dark Mode
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [clickaway],
  props: {
    loggedIn: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dark_mode: false,
      open: false,
      profile_open: false,
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content'),
    }
  },
  watch: {
    dark_mode() {
      if(this.dark_mode) {
        localStorage.setItem('theme', 'dark')
      }
      else {
        localStorage.setItem('theme', 'light')
      }

      this.updateDarkMode()
    }
  },
  mounted() {
    let theme = localStorage.getItem('theme')

    if(theme && theme == 'dark') {
      this.dark_mode = true
    }

    this.updateDarkMode()

  },
  methods: {
    updateDarkMode() {
      if(this.dark_mode) {
        document.body.classList.add('mode-dark')
      }
      else {
        document.body.classList.remove('mode-dark')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>