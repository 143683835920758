import { render, staticRenderFns } from "./ScoreboardDetail.vue?vue&type=template&id=0fca2e83&scoped=true&"
import script from "./ScoreboardDetail.vue?vue&type=script&lang=js&"
export * from "./ScoreboardDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fca2e83",
  null
  
)

export default component.exports