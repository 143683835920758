<template>
  <div>
    <header class="bg-white shadow dark:bg-gray-900 dark:text-white">
      <div
        :class="{'container' : !full, 'px-10': full}"
        class="mx-auto"
      >
        <div class="px-2 py-6 mx-auto max-w-7xl">
          <h2
            class="pl-1 text-3xl font-bold leading-tight text-gray-900 md:pl-0 dark:text-gray-100"
          >
            {{ title }}
          </h2>
          <div
            v-if="subtitle != ''"
            class="mt-2 font-semibold leading-tight tracking-wide text-red-500 uppercase text-md"
          >
            {{ subtitle }}
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  props: {
    full: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
</style>